.pageHeader {
  margin-top: calc(0px - var(--shell-dark-content-paddingTop, 20px));
  margin-right: calc(0px - var(--shell-dark-content-paddingLeft, 20px));
  margin-bottom: 0;
  margin-left: calc(0px - var(--shell-dark-content-paddingLeft, 20px));
  padding: var(--shell-dark-content-paddingTop, 20px) var(--shell-dark-content-paddingLeft, 20px);
  background-color: var(--color-white, #fff);
}

.title {
  display: block;
  color: var(--color-text1-4, #333);
  font-weight: var(--font-weight-3, bold);
  font-size: var(--font-size-title, 20px);
  line-height: 1.5;
}

.description {
  display: block;
  color: var(--color-text1-3, #666);
  font-size: var(--font-size-body-2, 14px);
  line-height: 1.5;
}